import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_payment_method = (size, page, search) => {
    return axiosApi
        .get(API_URL + `payment_method?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_payment_method = (bodyParam) => {
    return axiosApi
        .post(API_URL + "payment_method", bodyParam, { headers: authHeader})
        .then((response) => {
            return response.data;
        });
};

const edit_payment_method = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "payment_method/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const view_payment_method = (param) => {
    return axiosApi
        .get(API_URL + "payment_method/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multiple_payment_method = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_payment_method", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_payment_method = (param) => {
    return axiosApi
        .delete(API_URL + "payment_method/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const PaymentMethod = {
    all_payment_method,
    add_payment_method,
    edit_payment_method,
    view_payment_method,
    multiple_payment_method,
    delete_payment_method,
    API_URL,
};

export default PaymentMethod;