import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_template = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_template_excel?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_template = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_template_excel", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_template = (param) => {
    return axiosApi
        .delete(API_URL + "template_import_excel/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_template = (bodyParam) => {
    return axiosApi
        .post(API_URL + "template_import_excel", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_template = (param) => {
    return axiosApi
        .get(API_URL + "get_template_excel/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_template = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "template_import_excel/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const templateExcel = {
    all_template,
    multi_delete_template,
    delete_template,
    add_template,
    view_template,
    edit_template,
    API_URL,
};

export default templateExcel;