import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label, Button } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import ButtonDownloadTemplate from "../../components/Common/ButtonDownloadTemplate";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Class from "../../services/ClassService"
import Student from "../../services/StudentService"
import { useFormik } from "formik";
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms";
import Flatpickr from "react-flatpickr"
import { eventChannel } from "redux-saga";
import student from "../../services/StudentService";
import excelImport from '../../assets/images/excel-import.png';

const ListStudentSettings = () => {
    const [list_student, setListStudent] = useState([])
    const [list_class, setListClass] = useState([])
    const [list_class_kelulusan, setListClassKelulusan] = useState([])
    const [list_class_kenaikan, setListClassKenaikan] = useState([])
    const [list_class_pindah, setListClassPindah] = useState([])
    const [list_class_tinggal, setListClassTinggal] = useState([])
    const [get_section, setSection] = useState([])
    const [get_sheet, setSheetData] = useState([])
    const [upload_directory, setUploadDirectory] = useState("");
    const [view_student, setViewStudent] = useState("");
    const [view_class, setViewClass] = useState("");
    const [edit_student, setEditStudent] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [upload_modal, setupload_modal] = useState(false)
    const [kelulusan_modal, setkelulusan_modal] = useState(false)
    const [kenaikan_modal, setkenaikan_modal] = useState(false)
    const [pindah_modal, setpindah_modal] = useState(false)
    const [tinggal_modal, settinggal_modal] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [classId, setClassId] = useState('')
    const [className, setClassName] = useState('')
    const [schoolYear, setSchoolYear] = useState("");
    const [schoolYearUpload, setSchoolYearUpload] = useState("");
    const [schoolYearKelulusan, setSchoolYearKelulusan] = useState("");
    const [schoolYearKenaikan, setSchoolYearKenaikan] = useState("");
    const [schoolYearPindah, setSchoolYearPindah] = useState("");
    const [schoolYearTinggal, setSchoolYearTinggal] = useState("");
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        validationSearchType.setFieldValue('class_id', '');
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden";
        const controller = new AbortController()
        const newYearFrom = parseInt(validationUploadType.values.school_year_from);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYear(schoolYear)
        initData(10, 1, searchTerm, classId, schoolYear)
        return () => controller.abort()
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    let sections = "";
    const authUser = JSON.parse(localStorage.getItem("authUser"));

    if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
        !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
    ) {
        sections = authUser.sections[0];
    }

    const initData = async (size, page, search, class_id, school_year) => {
        try {
            const result = await Student.all_student(size, page, search, class_id, school_year, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                last_class: `[${item.school_year}] ${item.classes.class_name}`,
                ttl: item.place_of_birth + ", " + formatDate(item.date_of_birth),
                gender: item.gender === "L" ? "Laki - Laki" : "Perempuan",
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))

            const Class = await Student.get_class(sections, "", "")
            const formattedDataClass = Class.data.map((item, index) => ({
                ...item
            }))

            setListClass(formattedDataClass)
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListStudent(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickDownload(params) {
        console.log("Params :" + params)
    }

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        var btnDelete = document.getElementById("btnDelete")
        if (checkedBoxes.length > 1) {
            btnDelete.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelete.style.visibility = 'hidden';
        } else {
            btnDelete.style.visibility = 'hidden';
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '4%',
        },
        {
            name: 'Nomor Induk',
            selector: row => row.registration_no,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Nama Siswa',
            selector: row => row.student_name,
            sortable: true,
            width: '19%',
        },
        {
            name: 'Jenis Kelamin',
            selector: row => row.gender,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Tempat, Tanggal Lahir',
            selector: row => row.ttl,
            sortable: true,
            width: '14%',
        },
        {
            name: 'NISN',
            selector: row => row.student_nisn,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Kelas Terakhir',
            selector: row => row.last_class,
            sortable: true,
            width: '15%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleUploadChangeClass = (event) => {
        const selectedValue = event.target.value;
        validationUploadType.setFieldValue('class_id', selectedValue);
    };

    const handleKelulusanChangeClass = (event) => {
        const selectedValue = event.target.value;
        validationKelulusanType.setFieldValue('class_id', selectedValue);
    };

    const handleKenaikanChangeClass = (event) => {
        const selectedValue = event.target.value;
        validationKenaikanType.setFieldValue('class_id', selectedValue);
    };

    const handlePindahChangeClass = (event) => {
        const selectedValue = event.target.value;
        validationPindahType.setFieldValue('class_id', selectedValue);
    };

    const handleTinggalChangeClass = (event) => {
        const selectedValue = event.target.value;
        validationTinggalType.setFieldValue('class_id', selectedValue);
    };

    const handleChangeClass = (event) => {
        const selectedValue = event.target.value;
        const selectedText = event.target.options[event.target.selectedIndex].text;
        setClassId(selectedValue)
        setClassName(selectedText)
        validationSearchType.setFieldValue('class_id', selectedValue);
        initData(currentSize, currentPage, searchTerm, selectedValue, schoolYear)
    };

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm, classId, schoolYear)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm, classId)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickUpload = () => {
        setupload_modal(!upload_modal)
        const newYearFrom = parseInt(validationUploadType.values.school_year_from);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYearUpload(schoolYear)
    }

    const handleUploadChange = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData()
        formData.append('file', file)
        try {
            const response = await student.upload_student(formData);
            const path = response.data.file;
            const sheet = response.data.sheet;
            setUploadDirectory(path);
            setSheetData(sheet);
            resetForm();
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                // window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickAdd = () => {
        if (validationSearchType.values.class_id != "") {
            setadd_modal(!add_modal)
            validationAddType.resetForm()
            validationAddType.setFieldValue('is_active', "2");
            const newYearFrom = parseInt(validationSearchType.values.school_year_from);
            const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
            setSchoolYear(schoolYear)
            getSection()
        } else {
            ModalInfo(
                "Tidak ada pilihan",
                "Silahkan pilih kelas yang mau ditambahkan.",
                "warning",
                false
            )
        }
    };

    const handleClickKelulusan = async () => {
        if (validationSearchType.values.class_id != "") {
            let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
            let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
            if (arrayCheck.length === 0) {
                ModalInfo(
                    "Tidak ada pilihan",
                    "Silahkan pilih siswa yang akan diluluskan.",
                    "warning",
                    false
                )
                return;
            } else {
                const Class = await Student.get_class(sections, classId, "kelulusan")
                const formattedDataClass = Class.data.map((item, index) => ({
                    ...item
                }))

                setListClassKelulusan(formattedDataClass)

                setkelulusan_modal(!kenaikan_modal)
                validationKelulusanType.resetForm()
                const newYearFrom = parseInt(validationSearchType.values.school_year_from);
                const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
                setSchoolYearKelulusan(schoolYear)
            }
        } else {
            ModalInfo(
                "Tidak ada pilihan",
                "Silahkan pilih kelas untuk siswa yang mau diluluskan.",
                "warning",
                false
            )
        }
    }

    const handleClickKenaikan = async () => {
        if (validationSearchType.values.class_id != "") {
            let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
            let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
            if (arrayCheck.length === 0) {
                ModalInfo(
                    "Tidak ada pilihan",
                    "Silahkan pilih siswa yang akan dinaikkan kelas.",
                    "warning",
                    false
                )
                return;
            } else {
                const Class = await Student.get_class(sections, classId, "kenaikan")
                const formattedDataClass = Class.data.map((item, index) => ({
                    ...item
                }))

                setListClassKenaikan(formattedDataClass)

                setkenaikan_modal(!kenaikan_modal)
                validationKenaikanType.resetForm()
                const newYearFrom = parseInt(validationSearchType.values.school_year_from + 1);
                const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
                console.log("school_year_from : " + newYearFrom)
                setSchoolYearKenaikan(schoolYear)
            }
        } else {
            ModalInfo(
                "Tidak ada pilihan",
                "Silahkan pilih kelas untuk siswa yang mau dinaikkan.",
                "warning",
                false
            )
        }
    };

    const handleClickPindah = async () => {
        if (validationSearchType.values.class_id != "") {
            let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
            let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
            if (arrayCheck.length === 0) {
                ModalInfo(
                    "Tidak ada pilihan",
                    "Silahkan pilih siswa yang akan dipindahkan kelas.",
                    "warning",
                    false
                )
                return;
            } else {
                const Class = await Student.get_class(sections, classId, "pindah")
                const formattedDataClass = Class.data.map((item, index) => ({
                    ...item
                }))

                setListClassPindah(formattedDataClass)

                setpindah_modal(!pindah_modal)
                validationPindahType.resetForm()
                const newYearFrom = parseInt(validationSearchType.values.school_year_from);
                const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
                setSchoolYearPindah(schoolYear)
            }
        } else {
            ModalInfo(
                "Tidak ada pilihan",
                "Silahkan pilih kelas untuk siswa yang mau dipindahkan.",
                "warning",
                false
            )
        }
    };

    const handleClickTinggal = async () => {
        if (validationSearchType.values.class_id != "") {
            let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
            let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
            if (arrayCheck.length === 0) {
                ModalInfo(
                    "Tidak ada pilihan",
                    "Silahkan pilih siswa yang akan tinggal kelas.",
                    "warning",
                    false
                )
                return;
            } else {
                const Class = await Student.get_class(sections, classId, "tinggal")
                const formattedDataClass = Class.data.map((item, index) => ({
                    ...item
                }))

                setListClassTinggal(formattedDataClass)

                settinggal_modal(!tinggal_modal)
                validationTinggalType.resetForm()
                const newYearFrom = parseInt(validationSearchType.values.school_year_from + 1);
                const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
                setSchoolYearTinggal(schoolYear)
            }
        } else {
            ModalInfo(
                "Tidak ada pilihan",
                "Silahkan pilih kelas untuk siswa yang tidak naik.",
                "warning",
                false
            )
        }
    };

    function getSection() {
        let sections = "";

        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            sections = authUser.sections[0];
        }

        Class.get_section(sections).then(
            (response) => {
                if (response.data.length === 1) {
                    validationAddType.setFieldValue("section_id", response.data[0].id)
                    validationEditType.setFieldValue("section_id", response.data[0].id)
                    setSection(response.data)
                } else {
                    setSection(response.data)
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else if (action === "upload") {
            setupload_modal(false)
        } else if (action === "kelulusan") {
            setkelulusan_modal(false)
        } else if (action === "kenaikan") {
            setkenaikan_modal(false)
        } else if (action === "pindah") {
            setpindah_modal(false)
        } else if (action === "tinggal") {
            settinggal_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await Student.view_student(id);
            if (data) {
                const updatedData = {
                    ...data,
                    last_class: `[${data.school_year}] ${data.classes.class_name}`,
                    ttl: data.place_of_birth + ", " + formatDate(data.date_of_birth),
                    gender: data.gender === "L" ? "Laki - Laki" : "Perempuan",
                };

                if (data.is_active === 1) {
                    updatedData.status = <span className='badge-soft-info me-1 badge badge-secondary badge-pill'> Calon Siswa </span>;
                } else if (data.is_active === 2) {
                    updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Aktif </span>;
                } else if (data.is_active === 3) {
                    updatedData.status = <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Alumni </span>;
                } else if (data.is_active === 4) {
                    updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Keluar </span>;
                } else if (data.is_active === 5) {
                    updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Dikeluarkan </span>;
                } else if (data.is_active === 6) {
                    updatedData.status = <span className='badge-soft-warning me-1 badge badge-secondary badge-pill'> Pindah Sekolah </span>;
                } else if (data.is_active === 7) {
                    updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Meninggal Dunia </span>;
                }

                setViewStudent(updatedData);
                setViewClass(updatedData.classes);
                setview_modal(!view_modal);
                removeBodyCss();
            }
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout');
            } else if (errorCode === 403) {
                window.location.reload();
            } else {
                console.error('An unexpected error occurred:', error);
            }
        }
    };


    const handleClickEdit = (id) => {
        student.view_student(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditStudent(data)
                setViewClass(data.classes);
                getSection()
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        } else {
            ModalConfirm(
                "Are you sure?",
                "You won't be able to revert this!",
                "warning",
                "list student settings",
                () => processClickDeleteSelected(),
            )
        }
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await student.multi_delete_student({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, classId, schoolYear)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "list student settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await student.delete_student(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, classId, schoolYear)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleUploadSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYearUpload(schoolYear)
        validationUploadType.setFieldValue('school_year', schoolYear);
        validationUploadType.setFieldValue('school_year_from', newYearFrom);
        validationUploadType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handleKelulusanSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYearKelulusan(schoolYear)
        validationKelulusanType.setFieldValue('school_year', schoolYear);
        validationKelulusanType.setFieldValue('school_year_from', newYearFrom);
        validationKelulusanType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handleKenaikanSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYearKenaikan(schoolYear)
        validationKenaikanType.setFieldValue('school_year', schoolYear);
        validationKenaikanType.setFieldValue('school_year_from', newYearFrom);
        validationKenaikanType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handlePindahSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYearPindah(schoolYear)
        validationPindahType.setFieldValue('school_year', schoolYear);
        validationPindahType.setFieldValue('school_year_from', newYearFrom);
        validationPindahType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handleTinggalSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYearTinggal(schoolYear)
        validationTinggalType.setFieldValue('school_year', schoolYear);
        validationTinggalType.setFieldValue('school_year_from', newYearFrom);
        validationTinggalType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handleSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYear(schoolYear)
        validationSearchType.setFieldValue('school_year', schoolYear);
        initData(currentSize, currentPage, searchTerm, classId, schoolYear)
        validationSearchType.setFieldValue('school_year_from', newYearFrom);
        validationSearchType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value, classId, schoolYear)
        setFiltered(list_student)
    };

    document.title = `Daftar Siswa Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const current_year = new Date().getFullYear();

    const validationUploadType = useFormik({
        enableReinitialize: true,
        initialValues: {
            path: upload_directory,
            school_year_from: current_year,
            school_year_to: current_year + 1,
            sheet: "",
            school_year: schoolYearUpload,
            class_id: "",
            is_active: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await student.upload_student_process(values)
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationSearchType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: current_year,
            school_year_to: current_year + 1,
            section_id: "",
        },
        onSubmit: async (values, { resetForm }) => { }
    })

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            is_active: "",
            registration_no: "",
            student_name: "",
            gender: "",
            student_nik: "",
            student_nisn: "",
            place_of_birth: "",
            date_of_birth: "",
            address: "",
            section_id: "",
            fathers_name: "",
            fathers_phone_number: "",
            fathers_wa_number: "",
            mothers_name: "",
            mothers_phone_number: "",
            mothers_wa_number: "",
            notes: "",
            school_year: schoolYear,
            class_id: classId
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                values.registration_no = String(values.registration_no);
                values.fathers_phone_number = String(values.fathers_phone_number);
                values.fathers_wa_number = String(values.fathers_wa_number);
                values.mothers_phone_number = String(values.mothers_phone_number);
                values.mothers_wa_number = String(values.mothers_wa_number);
                values.student_nisn = String(values.student_nisn);
                values.student_nik = String(values.student_nik);
                await student.add_student(values)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            is_active: edit_student.is_active,
            registration_no: edit_student.registration_no,
            student_name: edit_student.student_name,
            gender: edit_student.gender,
            student_nik: edit_student.student_nik,
            student_nisn: edit_student.student_nisn,
            place_of_birth: edit_student.place_of_birth,
            date_of_birth: edit_student.date_of_birth,
            address: edit_student.address,
            section_id: edit_student.section_id,
            fathers_name: edit_student.fathers_name,
            fathers_phone_number: edit_student.fathers_phone_number,
            fathers_wa_number: edit_student.fathers_wa_number,
            mothers_name: edit_student.mothers_name,
            mothers_phone_number: edit_student.mothers_phone_number,
            mothers_wa_number: edit_student.mothers_wa_number,
            notes: edit_student.notes
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await student.edit_student(edit_student.id, values)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const current_year_kelulusan = validationSearchType.values.school_year_from;

    const validationKelulusanType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: current_year_kelulusan,
            school_year_to: current_year_kelulusan + 1,
            school_year: schoolYearKelulusan,
            class_id: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
                let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
                await student.process_graduation(arrayCheck, values)
                handleClickClose("kelulusan")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const current_year_kenaikan = validationSearchType.values.school_year_from + 1;

    const validationKenaikanType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: current_year_kenaikan,
            school_year_to: current_year_kenaikan + 1,
            school_year: schoolYearKenaikan,
            class_id: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
                let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
                await student.process_grade_promotion(arrayCheck, values)
                handleClickClose("kenaikan")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const current_year_pindah = validationSearchType.values.school_year_from;

    const validationPindahType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: current_year_pindah,
            school_year_to: current_year_pindah + 1,
            school_year: schoolYearPindah,
            class_id: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
                let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
                await student.process_change_class(arrayCheck, values)
                handleClickClose("pindah")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const current_year_tinggal = validationSearchType.values.school_year_from + 1;

    const validationTinggalType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: current_year_tinggal,
            school_year_to: current_year_tinggal + 1,
            school_year: schoolYearTinggal,
            class_id: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
                let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
                await student.process_repeat_class(arrayCheck, values)
                handleClickClose("tinggal")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Kesiswaan" breadcrumbItem="Daftar Siswa" />
                    <Row className="mb-3">
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <div style={{ flex: '0 0 130px' }}>
                                <Label className="form-label">
                                    <span style={{ color: 'red' }}>*</span> Tahun Pelajaran :
                                </Label>
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 85px' }}>
                                <Input
                                    type="number"
                                    name="school_year_from"
                                    id="school_year_from"
                                    value={validationSearchType.values.school_year_from}
                                    onChange={handleSchoolYear}
                                    style={{ width: '85px', height: '35px' }}
                                    required
                                />
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 100px' }}>
                                <Input
                                    type="number"
                                    name="school_year_to"
                                    id="school_year_to"
                                    value={validationSearchType.values.school_year_to}
                                    onChange={validationSearchType.handleChange}
                                    style={{ width: '85px', height: '35px' }}
                                    disabled
                                    required
                                />
                            </div>
                            <div style={{ flex: '0 0 70px', textAlign: 'left' }}>
                                <Label className="form-label">
                                    <span style={{ color: 'red' }}>*</span> Kelas :
                                </Label>
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 50px' }}>
                                <Input
                                    type="select"
                                    name="class_id"
                                    id="class_id"
                                    value={validationSearchType.values.class_id || ''}
                                    onChange={(e) => handleChangeClass(e)}
                                    style={{ width: '200px', height: '35px', textAlign: 'left' }}
                                    required
                                >
                                    <option value="">Semua Kelas</option>
                                    {list_class.map((classes, index) => (
                                        <option value={classes.id} key={index}>{classes.class_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Col md="10">
                            <Button id="btnAdd" onClick={handleClickKelulusan} className="btn btn-light waves-effect btn-label waves-light">
                                <i className="bx bx-book-reader label-icon"></i> Kelulusan
                            </Button>
                            <Button id="btnAdd" onClick={handleClickKenaikan} className="btn btn-light waves-effect btn-label waves-light" style={{ marginLeft: '16px' }}>
                                <i className="bx bx-building-house label-icon"></i> Kenaikan Kelas
                            </Button>
                            <Button id="btnAdd" onClick={handleClickPindah} className="btn btn-light waves-effect btn-label waves-light" style={{ marginLeft: '16px' }}>
                                <i className="bx bx-building-house label-icon"></i> Pindah Kelas
                            </Button>
                            <Button id="btnAdd" onClick={handleClickTinggal} className="btn btn-light waves-effect btn-label waves-light" style={{ marginLeft: '16px' }}>
                                <i className="bx bx-building-house label-icon"></i> Tinggal Kelas
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            textButtonAdd="Tambah"
                            isButtonDelete={true}
                            isButtonUpload={true}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                            handleClickUpload={handleClickUpload}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    size="lg"
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Menambah Siswa Baru Kelas {className}
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Data Siswa :</b></Label>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Data Ayah :</b></Label>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Status Terakhir</Label>
                                            <Input
                                                type="select"
                                                name="is_active"
                                                id="is_active"
                                                value={validationAddType.values.is_active || '2'}
                                                onChange={validationAddType.handleChange}
                                                required
                                            >
                                                <option value="1">Calon Siswa</option>
                                                <option value="2">Aktif</option>
                                                <option value="3">Alumni</option>
                                                <option value="4">Keluar</option>
                                                <option value="5">Dikeluarkan</option>
                                                <option value="6">Pindah Sekolah</option>
                                                <option value="7">Meninggal Dunia</option>
                                            </Input>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label">Nama Ayah</Label>
                                            <Input
                                                type="text"
                                                name="fathers_name"
                                                id="fathers_name"
                                                value={validationAddType.values.fathers_name}
                                                onChange={validationAddType.handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label"><span style={{ color: 'red' }}>*</span> Nomor Induk</Label>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Input
                                                    type="number"
                                                    name="registration_no"
                                                    id="registration_no"
                                                    value={validationAddType.values.registration_no}
                                                    onChange={validationAddType.handleChange}
                                                    required
                                                />

                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <span style={{ fontSize: 13, color: "grey" }}>Nomor Induk lokal di lembaga anda</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor HP</Label>
                                                <Input
                                                    type="number"
                                                    name="fathers_phone_number"
                                                    id="fathers_phone_number"
                                                    value={validationAddType.values.fathers_phone_number}
                                                    onChange={validationAddType.handleChange}
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor WA</Label>
                                                <Input
                                                    type="number"
                                                    name="fathers_wa_number"
                                                    id="fathers_wa_number"
                                                    value={validationAddType.values.fathers_wa_number}
                                                    onChange={validationAddType.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Nama Siswa</Label>
                                            <Input
                                                type="text"
                                                name="student_name"
                                                id="student_name"
                                                value={validationAddType.values.student_name}
                                                onChange={validationAddType.handleChange}
                                                required
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Data Ibu :</b></Label>
                                        <div className="mb-2">
                                            <Label className="form-label">Nama Ibu</Label>
                                            <Input
                                                type="text"
                                                name="mothers_name"
                                                id="mothers_name"
                                                value={validationAddType.values.mothers_name}
                                                onChange={validationAddType.handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Jenis Kelamin</Label>
                                            <Input
                                                type="select"
                                                name="gender"
                                                id="gender"
                                                value={validationAddType.values.gender || ''}
                                                onChange={validationAddType.handleChange}
                                                required
                                            >
                                                <option value="">Pilih Jenis Kelamin</option>
                                                <option value="L">Laki-Laki</option>
                                                <option value="P">Perempuan</option>
                                            </Input>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor HP</Label>
                                                <Input
                                                    type="number"
                                                    name="mothers_phone_number"
                                                    id="mothers_phone_number"
                                                    value={validationAddType.values.mothers_phone_number}
                                                    onChange={validationAddType.handleChange}
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor WA</Label>
                                                <Input
                                                    type="number"
                                                    name="mothers_wa_number"
                                                    id="mothers_wa_number"
                                                    value={validationAddType.values.mothers_wa_number}
                                                    onChange={validationAddType.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label">NIK</Label>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Input
                                                    type="number"
                                                    name="student_nik"
                                                    id="student_nik"
                                                    value={validationAddType.values.student_nik}
                                                    onChange={validationAddType.handleChange}
                                                    maxLength={16}
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <span style={{ fontSize: 13, color: "grey" }}>Nomor Induk Kependudukan</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2" style={{ flex: 1 }}>
                                            <Label className="form-label">Catatan</Label>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                id="notes"
                                                value={validationAddType.values.notes}
                                                onChange={validationAddType.handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label">NISN</Label>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Input
                                                    type="number"
                                                    name="student_nisn"
                                                    id="student_nisn"
                                                    value={validationAddType.values.student_nisn}
                                                    onChange={validationAddType.handleChange}
                                                />

                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <span style={{ fontSize: 13, color: "grey" }}>Nomor Induk Siswa Nasional</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tempat, Tanggal Lahir</Label>
                                                <Input
                                                    type="text"
                                                    name="place_of_birth"
                                                    id="place_of_birth"
                                                    value={validationAddType.values.place_of_birth}
                                                    onChange={validationAddType.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label" style={{ color: "white" }}>.</Label>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-yyyy"
                                                    value={validationAddType.values.date_of_birth}
                                                    onChange={([selectedDate]) => {
                                                        validationAddType.setFieldValue('date_of_birth', selectedDate);
                                                    }}
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d-m-Y",
                                                        dateFormat: "d-m-Y"
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label">Alamat</Label>
                                            <Input
                                                type="textarea"
                                                name="address"
                                                id="address"
                                                value={validationAddType.values.address}
                                                onChange={validationAddType.handleChange}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                            </table>

                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationAddType.values.section_id}
                                    onChange={validationAddType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Simpan</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Mengubah Data Siswa Kelas {view_class.class_name}
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">

                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Data Siswa :</b></Label>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Data Ayah :</b></Label>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Status Terakhir</Label>
                                            <Input
                                                type="select"
                                                name="is_active"
                                                id="is_active"
                                                value={validationEditType.values.is_active || '2'}
                                                onChange={validationEditType.handleChange}
                                                required
                                            >
                                                <option value="1">Calon Siswa</option>
                                                <option value="2">Aktif</option>
                                                <option value="3">Alumni</option>
                                                <option value="4">Keluar</option>
                                                <option value="5">Dikeluarkan</option>
                                                <option value="6">Pindah Sekolah</option>
                                                <option value="7">Meninggal Dunia</option>
                                            </Input>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label">Nama Ayah</Label>
                                            <Input
                                                type="text"
                                                name="fathers_name"
                                                id="fathers_name"
                                                value={validationEditType.values.fathers_name}
                                                onChange={validationEditType.handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label"><span style={{ color: 'red' }}>*</span> Nomor Induk</Label>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Input
                                                    type="number"
                                                    name="registration_no"
                                                    id="registration_no"
                                                    value={validationEditType.values.registration_no}
                                                    onChange={validationEditType.handleChange}
                                                    required
                                                />

                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <span style={{ fontSize: 13, color: "grey" }}>Nomor Induk lokal di lembaga anda</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor HP</Label>
                                                <Input
                                                    type="number"
                                                    name="fathers_phone_number"
                                                    id="fathers_phone_number"
                                                    value={validationEditType.values.fathers_phone_number}
                                                    onChange={validationEditType.handleChange}
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor WA</Label>
                                                <Input
                                                    type="number"
                                                    name="fathers_wa_number"
                                                    id="fathers_wa_number"
                                                    value={validationEditType.values.fathers_wa_number}
                                                    onChange={validationEditType.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Nama Siswa</Label>
                                            <Input
                                                type="text"
                                                name="student_name"
                                                id="student_name"
                                                value={validationEditType.values.student_name}
                                                onChange={validationEditType.handleChange}
                                                required
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Data Ibu :</b></Label>
                                        <div className="mb-2">
                                            <Label className="form-label">Nama Ibu</Label>
                                            <Input
                                                type="text"
                                                name="mothers_name"
                                                id="mothers_name"
                                                value={validationEditType.values.mothers_name}
                                                onChange={validationEditType.handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Jenis Kelamin</Label>
                                            <Input
                                                type="select"
                                                name="gender"
                                                id="gender"
                                                value={validationEditType.values.gender || ''}
                                                onChange={validationEditType.handleChange}
                                                required
                                            >
                                                <option value="">Pilih Jenis Kelamin</option>
                                                <option value="L">Laki-Laki</option>
                                                <option value="P">Perempuan</option>
                                            </Input>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor HP</Label>
                                                <Input
                                                    type="number"
                                                    name="mothers_phone_number"
                                                    id="mothers_phone_number"
                                                    value={validationEditType.values.mothers_phone_number}
                                                    onChange={validationEditType.handleChange}
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label">Nomor WA</Label>
                                                <Input
                                                    type="number"
                                                    name="mothers_wa_number"
                                                    id="mothers_wa_number"
                                                    value={validationEditType.values.mothers_wa_number}
                                                    onChange={validationEditType.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label">NIK</Label>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Input
                                                    type="number"
                                                    name="student_nik"
                                                    id="student_nik"
                                                    value={validationEditType.values.student_nik}
                                                    onChange={validationEditType.handleChange}
                                                    maxLength={16}
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <span style={{ fontSize: 13, color: "grey" }}>Nomor Induk Kependudukan</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2" style={{ flex: 1 }}>
                                            <Label className="form-label">Catatan</Label>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                id="notes"
                                                value={validationEditType.values.notes}
                                                onChange={validationEditType.handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label">NISN</Label>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Input
                                                    type="number"
                                                    name="student_nisn"
                                                    id="student_nisn"
                                                    value={validationEditType.values.student_nisn}
                                                    onChange={validationEditType.handleChange}
                                                />

                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <span style={{ fontSize: 13, color: "grey" }}>Nomor Induk Siswa Nasional</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tempat, Tanggal Lahir</Label>
                                                <Input
                                                    type="text"
                                                    name="place_of_birth"
                                                    id="place_of_birth"
                                                    value={validationEditType.values.place_of_birth}
                                                    onChange={validationEditType.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-2" style={{ flex: 1 }}>
                                                <Label className="form-label" style={{ color: "white" }}>.</Label>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-yyyy"
                                                    value={validationEditType.values.date_of_birth}
                                                    onChange={([selectedDate]) => {
                                                        validationEditType.setFieldValue('date_of_birth', selectedDate);
                                                    }}
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d-m-Y",
                                                        dateFormat: "d-m-Y"
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-2">
                                            <Label className="form-label">Alamat</Label>
                                            <Input
                                                type="textarea"
                                                name="address"
                                                id="address"
                                                value={validationEditType.values.address}
                                                onChange={validationEditType.handleChange}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                            </table>

                            <div className="mb-3" hidden>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationEditType.values.section_id}
                                    onChange={validationEditType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Simpan</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    size="lg"
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Lihat Data Lengkap Siswa Kelas {view_class.class_name}
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 17 }}><b>Data Siswa</b></Label>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 17 }}><b>Data Ayah</b></Label>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Status Terakahir</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.status}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nama Ayah</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.fathers_name}
                                            </Col>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nomor Induk</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.registration_no}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nomor HP</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.fathers_phone_number}
                                            </Col>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nama Siswa</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.student_name}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nomor WA</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.fathers_wa_number}
                                            </Col>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Jenis Kelamin</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.gender}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>NIK</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.student_nik}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 17 }}><b>Data Ibu</b></Label>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>NISN</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.student_nisn}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nama Ibu</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.mothers_name}
                                            </Col>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>TTL</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.ttl}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nomor HP</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.mothers_phone_number}
                                            </Col>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Alamat</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.address}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Nomor WA</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.mothers_wa_number}
                                            </Col>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Catatan</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.notes}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="row">
                                            <Col xl="4">
                                                <b>Kelas Terakhir</b>
                                            </Col>
                                            <Col xl="1" style={{ textAlign: "right" }}>
                                                :
                                            </Col>
                                            <Col xl="7">
                                                {view_student.last_class}
                                            </Col>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                                handleClickClose("view")
                            }
                        >
                            Tutup
                        </button>
                    </div>
                </Modal>

                <Modal
                    size="lg"
                    isOpen={upload_modal}
                    toggle={() => {
                        handleClickUpload()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Unggah Siswa Baru
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("upload")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationUploadType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Import Dari File Excel :</b></Label>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <Label className="form-label" style={{ fontSize: 16 }}><b>Tujuan :</b></Label>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Lokasi File :</Label>
                                            <Input
                                                type="file"
                                                name="banner_image"
                                                id="banner_image"
                                                placeholder="Enter banner image"
                                                accept=".xls,.xlsx"
                                                onChange={handleUploadChange}
                                                required
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tahun Pelajaran :</Label>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <div style={{ flex: '0 0 85px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_from"
                                                        id="school_year_from"
                                                        value={validationUploadType.values.school_year_from}
                                                        onChange={handleUploadSchoolYear}
                                                        style={{ width: '85px', height: '35px' }}
                                                        required
                                                    />
                                                </div>
                                                <div style={{ flex: '0 0 100px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_to"
                                                        id="school_year_to"
                                                        value={validationUploadType.values.school_year_to}
                                                        onChange={validationUploadType.handleChange}
                                                        style={{ width: '85px', height: '35px' }}
                                                        disabled
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Sheet :</Label>
                                            <Input
                                                type="select"
                                                name="sheet"
                                                id="sheet"
                                                value={validationUploadType.values.sheet || ''}
                                                onChange={validationUploadType.handleChange}
                                                required
                                            >
                                                <option value="">Pilih Sheet</option>
                                                {get_sheet.map((sheet, index) => (
                                                    <option value={sheet} key={index}>{sheet}</option>
                                                ))}
                                            </Input>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Kelas :</Label>
                                            <Input
                                                type="select"
                                                name="class_id"
                                                id="class_id"
                                                value={validationUploadType.values.class_id || ''}
                                                onChange={(e) => handleUploadChangeClass(e)}
                                                required
                                            >
                                                <option value="">Pilih Kelas</option>
                                                {list_class.map((classes, index) => (
                                                    <option value={classes.id} key={index}>{classes.class_name}</option>
                                                ))}
                                            </Input>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Status Terakhir</Label>
                                            <Input
                                                type="select"
                                                name="is_active"
                                                id="is_active"
                                                value={validationUploadType.values.is_active || validationUploadType.setFieldValue('is_active', 2)}
                                                onChange={validationUploadType.handleChange}
                                                required
                                            >
                                                <option value="1">Calon Siswa</option>
                                                <option value="2">Aktif</option>
                                                <option value="3">Alumni</option>
                                                <option value="4">Keluar</option>
                                                <option value="5">Dikeluarkan</option>
                                                <option value="6">Pindah Sekolah</option>
                                                <option value="7">Meninggal Dunia</option>
                                            </Input>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}></td>
                                </tr>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <ButtonDownloadTemplate
                                isButtonDownload={true}
                                textButtonDownload={"Contoh Format"}
                                textButtonParams={"STUDENT_LIST"}
                            />
                            <button className="btn btn-info" type="submit"><img src={excelImport} width={"20px"} style={{ marginRight: "10px" }} />
                                Proses Impor
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("upload")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={kelulusan_modal}
                    toggle={() => {
                        handleClickKelulusan()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Kelulusan
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("kelulusan")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationKelulusanType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <Label className="form-label" style={{ fontSize: '15px' }}>
                                Silahkan Pilih Kelas Tujuan
                            </Label><br />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tahun Pelajaran :</Label>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <div style={{ flex: '0 0 85px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_from"
                                                        id="school_year_from"
                                                        value={validationKelulusanType.values.school_year_from}
                                                        onChange={handleKelulusanSchoolYear}
                                                        style={{ width: '180px', height: '35px' }}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ flex: '0 0 100px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_to"
                                                        id="school_year_to"
                                                        value={validationKelulusanType.values.school_year_to}
                                                        onChange={validationKelulusanType.handleChange}
                                                        style={{ width: '180px', height: '35px' }}
                                                        disabled
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Kelas :</Label>
                                            <Input
                                                type="select"
                                                name="class_id"
                                                id="class_id"
                                                value={validationKelulusanType.values.class_id || ''}
                                                onChange={(e) => handleKelulusanChangeClass(e)}
                                                required
                                                disabled
                                            >
                                                {list_class_kelulusan.map((classes, index) => (
                                                    <option value={classes.id} key={index}>{classes.class_name}</option>
                                                ))}
                                            </Input>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-info" type="submit">
                                Proses Kelulusan
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("kelulusan")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={kenaikan_modal}
                    toggle={() => {
                        handleClickKenaikan()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Kenaikan Kelas
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("kenaikan")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationKenaikanType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <Label className="form-label" style={{ fontSize: '15px' }}>
                                Silahkan Pilih Kelas Tujuan
                            </Label><br />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tahun Pelajaran :</Label>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <div style={{ flex: '0 0 85px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_from"
                                                        id="school_year_from"
                                                        value={validationKenaikanType.values.school_year_from}
                                                        onChange={handleKenaikanSchoolYear}
                                                        style={{ width: '180px', height: '35px' }}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ flex: '0 0 100px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_to"
                                                        id="school_year_to"
                                                        value={validationKenaikanType.values.school_year_to}
                                                        onChange={validationKenaikanType.handleChange}
                                                        style={{ width: '180px', height: '35px' }}
                                                        disabled
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Kelas :</Label>
                                            <Input
                                                type="select"
                                                name="class_id"
                                                id="class_id"
                                                value={validationKenaikanType.values.class_id || ''}
                                                onChange={(e) => handleKenaikanChangeClass(e)}
                                                required
                                            >
                                                <option value="">Pilih Kelas</option>
                                                {list_class_kenaikan.map((classes, index) => (
                                                    <option value={classes.id} key={index}>{classes.class_name}</option>
                                                ))}
                                            </Input>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-info" type="submit">
                                Proses Kenaikan Kelas
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("kenaikan")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={pindah_modal}
                    toggle={() => {
                        handleClickPindah()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Pindah Kelas
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("pindah")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationPindahType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <Label className="form-label" style={{ fontSize: '15px' }}>
                                Silahkan Pilih Kelas Tujuan
                            </Label><br />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tahun Pelajaran :</Label>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <div style={{ flex: '0 0 85px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_from"
                                                        id="school_year_from"
                                                        value={validationPindahType.values.school_year_from}
                                                        onChange={handlePindahSchoolYear}
                                                        style={{ width: '180px', height: '35px' }}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ flex: '0 0 100px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_to"
                                                        id="school_year_to"
                                                        value={validationPindahType.values.school_year_to}
                                                        onChange={validationPindahType.handleChange}
                                                        style={{ width: '180px', height: '35px' }}
                                                        disabled
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Kelas :</Label>
                                            <Input
                                                type="select"
                                                name="class_id"
                                                id="class_id"
                                                value={validationPindahType.values.class_id || ''}
                                                onChange={(e) => handlePindahChangeClass(e)}
                                                required
                                            >
                                                <option value="">Pilih Kelas</option>
                                                {list_class_pindah.map((classes, index) => (
                                                    <option value={classes.id} key={index}>{classes.class_name}</option>
                                                ))}
                                            </Input>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-info" type="submit">
                                Proses Pindah Kelas
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("pindah")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={tinggal_modal}
                    toggle={() => {
                        handleClickTinggal()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Tinggal Kelas
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("tinggal")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationTinggalType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <Label className="form-label" style={{ fontSize: '15px' }}>
                                Silahkan Pilih Kelas Tujuan
                            </Label><br />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Tahun Pelajaran :</Label>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <div style={{ flex: '0 0 85px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_from"
                                                        id="school_year_from"
                                                        value={validationTinggalType.values.school_year_from}
                                                        onChange={handleTinggalSchoolYear}
                                                        style={{ width: '180px', height: '35px' }}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ flex: '0 0 100px' }}>
                                                    <Input
                                                        type="number"
                                                        name="school_year_to"
                                                        id="school_year_to"
                                                        value={validationTinggalType.values.school_year_to}
                                                        onChange={validationTinggalType.handleChange}
                                                        style={{ width: '180px', height: '35px' }}
                                                        disabled
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '50%' }}>
                                        <div className="mb-3">
                                            <Label className="form-label"><span style={{ color: 'red' }}>*</span> Kelas :</Label>
                                            <Input
                                                type="select"
                                                name="class_id"
                                                id="class_id"
                                                value={validationTinggalType.values.class_id || ''}
                                                onChange={(e) => handleTinggalChangeClass(e)}
                                                required
                                            >
                                                <option value="">Pilih Kelas</option>
                                                {list_class_tinggal.map((classes, index) => (
                                                    <option value={classes.id} key={index}>{classes.class_name}</option>
                                                ))}
                                            </Input>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-info" type="submit">
                                Proses Tinggal Kelas
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("tinggal")
                                }
                            >
                                Batal
                            </button>
                        </div>
                    </Form>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default ListStudentSettings;
