import React from "react";
import { Button, Input, Row, Col } from "reactstrap";
import excelFormat from '../../assets/images/excel-format.png';
import PropTypes from 'prop-types';
import common from "../../services/CommonService"
import { IMAGE_URL } from "../../helpers/api_helper"

const ButtonDownloadTemplate = ({
    isButtonDownload,
    textButtonDownload,
    textButtonParams
}) => {

    const handleClickDownload = async (params) => {
        try {
            const data = await common.get_button_template(params)
            if (data) {
                const anchor = document.createElement('a');
                anchor.href = IMAGE_URL + data.file;

                anchor.download = data.name;

                anchor.click();
                anchor.remove();
            } else {
                console.error('No data found for the template download');
            }
        } catch (error) {
            const errorCode = error.response?.data?.status;
            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    return (
        <React.Fragment>
            {isButtonDownload && (
                <Button id="btnDownloadTemplate" onClick={() => handleClickDownload(textButtonParams)} className="btn btn-info"><img src={excelFormat} width={"20px"} style={{ marginRight: "10px" }} />
                    {textButtonDownload}
                </Button>
            )}
        </React.Fragment>
    );
};

ButtonDownloadTemplate.propTypes = {
    isButtonDownload: PropTypes.bool
};

export default ButtonDownloadTemplate;
