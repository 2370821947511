import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_kas = (size, page, search, section) => {
    return axiosApi
        .get(API_URL + `kas?limit=${size}&page=${page}&search=${search}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const list_kas = (size, page, search) => {
    return axiosApi
        .get(API_URL + `kas`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_kas = (bodyParam) => {
    return axiosApi
        .post(API_URL + "kas", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_kas = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "kas/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const view_kas = (param) => {
    return axiosApi
        .get(API_URL + "kas/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multiple_kas = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_kas", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_kas = (param) => {
    return axiosApi
        .delete(API_URL + "kas/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const Kas = {
    all_kas,
    add_kas,
    edit_kas,
    view_kas,
    multiple_kas,
    delete_kas,
    list_kas,
    API_URL,
};

export default Kas;