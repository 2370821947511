import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_section = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_section?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_section = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_section", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_section = (bodyParam) => {
    return axiosApi
        .post(API_URL + "section", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_section = (param) => {
    return axiosApi
        .get(API_URL + "get_section/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_section = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "section/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_section = (param) => {
    return axiosApi
        .delete(API_URL + "section/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_section = (section) => {
    return axiosApi
        .get(API_URL + `get_section?section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const section = {
    all_section,
    multi_delete_section,
    add_section,
    view_section,
    edit_section,
    delete_section,
    get_section,
    API_URL,
};

export default section;