import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_reception_post = (size, page, search, section) => {
    return axiosApi
        .get(API_URL + `reception_post?limit=${size}&page=${page}&search=${search}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_reception_post = (bodyParam) => {
    return axiosApi
        .post(API_URL + "reception_post", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_reception_post = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "reception_post/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const view_reception_post = (param) => {
    return axiosApi
        .get(API_URL + "reception_post/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multiple_reception_post = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_reception_post", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_reception_post = (param) => {
    return axiosApi
        .delete(API_URL + "reception_post/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const ReceptionPost = {
    all_reception_post,
    add_reception_post,
    edit_reception_post,
    view_reception_post,
    multiple_reception_post,
    delete_reception_post,
    API_URL,
};

export default ReceptionPost;