import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const get_button_template = (param) => {
    return axiosApi
        .get(API_URL + "common_button_template/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const Common = {
    get_button_template,
    API_URL,
};

export default Common;