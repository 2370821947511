import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//MenuManagement
import CategoryMenu from "../pages/MenuManagement/CategoryMenu";
import Menus from "../pages/MenuManagement/Menus";
import Submenus from "../pages/MenuManagement/Submenu";

//HomeAppSettings
import BannerSettings from "../pages/HomeMenu/BannerSettings";
import PembelianSettings from "../pages/HomeMenu/PembelianSettings";
import PembayaranSettings from "../pages/HomeMenu/PembayaranSettings";
import KindnessSettings from "../pages/HomeMenu/KindnessSettings";
import MainMenuSettings from "../pages/HomeMenu/MainMenuSettings";

//UsersManagement
import RoleSettings from "../pages/UsersManagement/RoleSettings";
import SectionSettings from "../pages/UsersManagement/SectionSettings";
import UserSettings from "../pages/UsersManagement/UserSettings";
import MobUserSettings from "../pages/MobUsersManagement/UserSettings";

//Masjid Settings
// import MasjidSettings from "../pages/Masjid/MasjidSettings";
// import HistoryInfaq from "../pages/Masjid/HistoryInfaq";
// import GaleriPenyaluranInfaq from "../pages/Masjid/GaleriPenyaluranInfaq";

//Pembayaran
import ListClass from "../pages/Payment/ListClassSettings";
import ListStudent from "../pages/Payment/ListStudentSettings";

//Kindness
// import MasjidSettings from "../pages/Masjid/MasjidSettings";
import HistoryBeasiswaTahfidz from "../pages/Kindness/HistoryBeasiswaTahfidz";
import HistoryTerasKoperasi from "../pages/Kindness/HistoryTerasKoperasi";
import HistoryWakafProduktif from "../pages/Kindness/HistoryWakafProduktif";
import GaleriPenyaluranBeasiswa from "../pages/Kindness/GaleriPenyaluranBeasiswa";
import GaleriPenyaluranTerasKoperasi from "../pages/Kindness/GaleriPenyaluranTerasKoperasi";
import GaleriPenyaluranWakafProduktif from "../pages/Kindness/GaleriPenyaluranWakafProduktif";
import KebaikanSettings from "../pages/Kindness/KebaikanSettings";

import WebSettings from "../pages/WebSettings";

//Template Import Excel
import TemplateImportExcel from "../pages/TemplateImportExcel/index";

//Payment Management
import TransferBankSettings from "../pages/PaymentManagement/TransferBankSettings";

//Al-Qur'an Settings
import SurahSettings from "../pages/AlQuran/SurahSettings";
import AyatSettings from "../pages/AlQuran/AyatSettings";

//Product Settings
import PrepaidSettings from "../pages/Product/PrepaidSettings";
import PostpaidSettings from "../pages/Product/PostpaidSettings";
import LogoProductSettings from "../pages/Product/LogoProductSettings";

import ProductSettings from "../pages/Product/ProductSettings";
import OnlineSaleList from "../pages/Product/OnlineSaleList";
import OfflineSaleList from "../pages/Product/OfflineSaleList";

//Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//History
import HistorySaldo from "../pages/Wallet/HistorySaldo";
import TopupServer from "../pages/SaldoServer/index";
import HistoryTopup from "../pages/Wallet/HistoryTopup";
import PendingTransaction from "../pages/Transaction/PendingTransaction";

// Keuangan
import KasPage from "../pages/Keuangan/KasPage";
import PaymentMethodPage from "../pages/Keuangan/PaymentMethodPage";
import ReceptionPostPage from "../pages/Keuangan/ReceptionPostPage";


const userRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  //menu management
  { path: "/developer/category-menu-settings", component: <CategoryMenu /> },
  { path: "/developer/menu-settings", component: <Menus /> },
  { path: "/developer/submenu-settings", component: <Submenus /> },

  //home app settings
  { path: "/admin/banner-settings", component: <BannerSettings /> },
  { path: "/admin/menu-pembelian-settings", component: <PembelianSettings /> },
  { path: "/admin/menu-pembayaran-settings", component: <PembayaranSettings /> },
  { path: "/admin/menu-kebaikan-settings", component: <KindnessSettings /> },
  { path: "/admin/menu-utama-settings", component: <MainMenuSettings /> },

  //users management
  { path: "/developer/role-settings", component: <RoleSettings /> },
  { path: "/developer/user-settings", component: <UserSettings /> },
  { path: "/developer/section-settings", component: <SectionSettings /> },
  { path: "/admin/user-settings", component: <MobUserSettings /> },

  // Pembayaran
  { path: "/payment/list-class-settings", component: <ListClass /> },
  { path: "/payment/list-student-settings", component: <ListStudent /> },

  //kindness
  { path: "/admin/history-beasiswa-tahfidz", component: <HistoryBeasiswaTahfidz /> },
  { path: "/admin/history-teras-koperasi", component: <HistoryTerasKoperasi /> },
  { path: "/admin/history-wakaf-produktif", component: <HistoryWakafProduktif /> },
  { path: "/admin/galeri-penyaluran-beasiswa-tahfidz", component: <GaleriPenyaluranBeasiswa /> },
  { path: "/admin/galeri-penyaluran-teras-koperasi", component: <GaleriPenyaluranTerasKoperasi /> },
  { path: "/admin/galeri-penyaluran-wakaf-produktif", component: <GaleriPenyaluranWakafProduktif /> },
  { path: "/admin/kebaikan-settings", component: <KebaikanSettings /> },

  { path: "/developer/web-settings", component: <WebSettings /> },

  //template import excel
  { path: "/developer/template-import-excel", component: <TemplateImportExcel /> },

  //payment management
  { path: "/admin/transfer-bank-settings", component: <TransferBankSettings /> },

  //al-qur'an settings
  { path: "/admin/surah-settings", component: <SurahSettings /> },
  { path: "/admin/ayat-settings", component: <AyatSettings /> },

  //product
  { path: "/admin/prepaid-product-settings", component: <PrepaidSettings /> },
  { path: "/admin/postpaid-product-settings", component: <PostpaidSettings /> },
  { path: "/admin/logo-product-settings", component: <LogoProductSettings /> },

  { path: "/kasir/list-product-settings", component: <ProductSettings /> },
  { path: "/kasir/online-sales", component: <OnlineSaleList /> },
  { path: "/kasir/offline-sales", component: <OfflineSaleList /> },

  //profile
  { path: "/profile", component: <UserProfile /> },

  //history
  { path: "/admin/history-saldo", component: <HistorySaldo /> },
  { path: "/admin/topup-server", component: <TopupServer /> },
  { path: "/admin/history-topup", component: <HistoryTopup /> },
  { path: "/admin/pending-transaction", component: <PendingTransaction /> },

  // kas
  { path: "/payment/list-kas", component: <KasPage /> },
  { path: "/payment/payment-method", component: <PaymentMethodPage /> },
  { path: "/payment/reception-post", component: <ReceptionPostPage /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/panel/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

];

export { userRoutes, authRoutes };
